<template>
  <div>
    <b-form @submit.stop.prevent="formOnsubmit">
      <b-form-group
        id="input-group-law-product-category"
        label="Kategori:"
        label-for="input-law-product-category"
      >
        <treeselect
          v-model="form.law_product_category_id"
          :multiple="false"
          placeholder="Pilih Kategori"
          :options="law_product_categories"
        />
        <small class="text-danger">{{ error.law_product_category_id }}</small>
      </b-form-group>

      <!-- Name input -->
      <b-form-group
        id="input-group-title"
        label="Judul:"
        label-for="input-title"
      >
        <b-form-input
          id="input-title"
          v-model="form.title"
          placeholder="Judul Produk Hukum"
        ></b-form-input>
        <small class="text-danger">{{ error.title }}</small>
      </b-form-group>

      <!-- Description input -->
      <b-form-group id="input-group-description">
        <label for="input-description"
          >Deskripsi: <em class="text-muted">opsional</em></label
        >
        <b-form-textarea
          id="input-description"
          v-model="form.description"
          placeholder="Deksripsi Produk Hukum"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.description }}</small>
      </b-form-group>

      <button
        class="btn btn-info btn-block mb-5"
        type="button"
        @click="$refs.inputFile.$el.childNodes[0].click()"
      >
        <i class="fas fa-fw fa-upload"></i> Upload File
      </button>
      <b-form-file
        ref="inputFile"
        v-model="form.document_url"
        placeholder="Upload File"
        drop-placeholder="Drop file here..."
        @change="onFileUpload"
        style="display: none"
      ></b-form-file>
      <span v-if="purpose == 'edit'">File: <a class="btn btn-outline-primary" :href="form.document_url" download target="_blank"> <i class="fas fa-download"></i> <br> Download</a></span>
      <small class="text-danger">{{ error.document_url }}</small>
      <br />
      <br />

      <!-- Submit & Cancel button -->
      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="$router.push('/law-products/list')"
      >
        Batal
      </b-button>
    </b-form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    form: Object,
    route: String,
    purpose: {
      type: String,
      default: "add",
    },
    summonPurpose: {
      type: String,
      default: "master",
    },
  },

  data() {
    return {
      // Error
      error: {
        title: "",
        description: "",
        law_product_category_id: "",
      },
      law_product_categories: [],
    };
  },

  methods: {
    onFileUpload(evt) {
      this.form.document_url = evt.target.files[0];
      console.log("village_chief_photo", this.form.document_url);
    },
    async getLawProductCategoryOption() {
      let response = await module.setTreeSelect("law-product-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.law_product_categories = response.data;
        this.law_product_categories.unshift({
          label: "Pilih Kategroi Produk Hukum",
          id: "",
          isDisabled: true,
        });
      }
    },
    async formOnsubmit() {
      console.log("form", this.form);
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");

        if (this.summonPurpose == "master") {
          this.$router.push("/law-products");
        } else {
          this.$emit("submitedProfession", {
            id: response.success.item.original.id,
            name: response.success.item.original.name,
          });
        }
      }
    },
  },
  mounted() {
    this.getLawProductCategoryOption();
  },
};
</script>

<style>
</style>